<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">浙江省数字化设计与制造创新中心</p>
      <p class="company-name">(浙江西图盟数字科技有限公司)</p>
      <p class="company-description">
        打造百亿级的智能制造工业软件 / 智能工厂数字化技术服务 /
        智能工厂成套解决方案产业链
      </p>
      <div>
        <div class="item-list">
          <div
            class="item"
            v-for="(item, index) in categoryList"
            :key="index"
            :style="{
              background: `url(${item.background}) no-repeat center center`,
            }"
          >
            <div class="item-title" v-html="item.title"></div>
            <p
              class="item-content"
              v-for="content in item.itemList"
              :key="content"
              v-html="content"
            ></p>
          </div>
          <div
            class="item-design item"
            :style="{ background: `url(${designImg}) no-repeat center center` }"
          >
            <div class="item-title">浙江省数字化设计与制造创新中心</div>
            <ul class="design-list">
              <li>软件开发：安全自主工业软件</li>
              <li>人才培养：工业4.0复合型人才培养</li>
              <li>聚焦领域：工业软件、物联网、工程技术服务</li>
            </ul>
            <div class="file-img">
              <img
                class="detail"
                src="../../../assets/home/design/image1.png"
              />
              <img class="file" src="../../../assets/home/design/image2.png" />
            </div>
          </div>
        </div>
        <div class="work-type-list">
          <div class="work-item " v-for="(item, index) in typeList" :key="index">
            <div class="content">
              <div class="work-title">{{ item.title }}</div>
              <div class="work-content">{{ item.description }}</div>
            </div>
            <div class="img-container"><img :class="`work-item-${index}`" :src="item.imgUrl" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      categoryList: [
        {
          title: '工业物联网研究院',
          background: require('../../..//assets/home/design/img1-bg.png'),
          itemList: [
            '面向工业互联网和车联网全业务场景',
            '低时延、大带宽的5G<br/>工业网络/车联网集成标准研究',
          ],
        },
        {
          title: '数字化工厂系统<br/>解决方案',
          background: require('../../..//assets/home/design/img2-bg.png'),
          itemList: [
            '基于独立工艺单元，根据产品定制化需求，',
            '人、机、料、法、环、测即插即用的组合式规模化生产',
          ],
        },
        {
          title: '工业大脑',
          background: require('../../..//assets/home/design/img3-bg.png'),
          itemList: [
            '在线定制交互<br/>焊装虚拟工厂在线交互<br/>VCATS运程管理与服务',
            '双向驱动实时动态控制<br/>数字孪生，虚实交互',
          ],
        },
        {
          title: 'Devops协同开发平台',
          background: require('../../..//assets/home/design/img4-bg.png'),
          itemList: [
            '搭建数据平台与AI模型算法平台，标准化流程',
            '低代码方式让开发与业务人员将行业知识快速 转化为工业APP',
          ],
        },
      ],
      designImg: require('../../../assets/home/design/img5-bg.png'),
      typeList: [
        {
          title: '汽车行业',
          imgUrl: require('../../../assets/home/design/icon1.png'),
          description: '汽车行业生产管理高要求',
        },
        {
          title: '产业集群',
          imgUrl: require('../../../assets/home/design/icon2.png'),
          description: '数字经济促进产业集群发展',
        },
        {
          title: '行业通用',
          imgUrl: require('../../../assets/home/design/icon3.png'),
          description: '数字化推动企业创新重塑',
        },
        {
          title: '政务服务',
          imgUrl: require('../../../assets/home/design/icon4.png'),
          description: '政府数字化转型，数据驱动',
        },
        {
          title: '化工行业',
          imgUrl: require('../../../assets/home/design/icon5.png'),
          description: '化工行业信息化建设覆盖',
        },
        {
          title: '物流行业',
          imgUrl: require('../../../assets/home/design/icon6.png'),
          description: '物流供应链集成化和智能化',
        },
        {
          title: '设备制造',
          imgUrl: require('../../../assets/home/design/icon7.png'),
          description: '设备制造数字化转型',
        },
        {
          title: '家电行业',
          imgUrl: require('../../../assets/home/design/icon8.png'),
          description: '“物联网+家电”融合',
        },
        {
          title: '科研专题',
          imgUrl: require('../../../assets/home/design/icon9.png'),
          description: '市场需求驱动研发生产一体化',
        },
        {
          title: '多行业覆盖',
          imgUrl: require('../../../assets/home/design/icon10.png'),
          description: '专注离散制造业智造服务',
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: #fff;
  min-height: 1000px;
  overflow: hidden;
  text-align: center;
}

.container {
  .g_center();
  height: 100%;
}

.title {
  margin-top: 122px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #2c3844;
}

.description {
  position: relative;
  z-index: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #5b5b5b;
}

.swiper_wrapper {
  position: relative;
  width: @g_width;
  min-height: 656px;
  margin: 67px auto 0 auto;
  background: #fff;
  box-shadow: 0px 9px 50px rgba(0, 0, 0, 0.05);
}

.item-list {
  display: flex;
  justify-content: space-between;

  .item {
    width: 192px;
    height: 426px;
    color: #fff;
    .item-title {
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      padding: 20px 20px;
      height: 44px;
    }
    .item-content {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      padding: 15px;
    }
  }

  .item-design {
    width: 553px;
    color: #000;
    .item-title {
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      padding: 20px 30px;
      height: 44px;
    }
    .design-list {
      padding: 0 40px;
      list-style: unset;
      text-align: left;
      li {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
}
/deep/ .swiper-pagination-bullet-home-serve {
  width: 56px;
  height: 12px;
  margin-right: 9px;
  border-radius: 0;
  background: #c4c4c4;
  opacity: 1;

  &:hover,
  &.swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
}
.company-name {
  font-size: 18px;
  line-height: 30px;
  color: #555;
  letter-spacing: 0.05em;
  padding-bottom: 15px;
}
.company-description {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;

  padding-bottom: 50px;
}
.file-img {
  display: flex;
  margin-top: 50px;
  margin-left: 30px;

  .detail {
    width: 287px;
    height: 183px;
    margin-right: 20px;
  }
  .file {
    width: 186px;
    height: 183px;
  }
}
.work-type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .work-item {
    width: 264px;
    height: 106px;
    box-shadow: 0px 4px 16px 0px #00000014;
    background: linear-gradient(180deg, #f4f6f8 0%, #fefeff 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:25px;
    .img-container{
      width: 60px;
      height: 60px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
    }
     .work-item-5{
      width:54px;
      height:42px;
     }
    .work-item-6{
      width:45px;
      height:42px;
     }
      .work-item-7{
      width:50px;
      height:50px;
     }
     img{
      width:60px;
      height:60px;
    }
    .content{
     padding:25px 0  25px 25px;

    }
    .work-title {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;

    }
    .work-content {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      text-align: left;
      color: #99a5ae;
    }

  }
}
</style>
