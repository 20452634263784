<template>
  <div class="dynamic">
    <div class="dynamic-container">
    <div class="industryTrends">
      <div class="industryTrends-title">行业动态</div>
      <div class="industryTrends-contentBoxList">
        <div class="itemBox">
          <div class="contentList-left">
            <div
              class="itemLeft"
              v-for="(item, index) in indexItemLeftdata"
              :key="index"
              @click="handleJumpNew(index ,indexItemLeftdata)"
            >
              <div class="itemLeft-title">{{ item.title }}</div>
              <div class="itemLeft-text">{{ item.text }}</div>
              <div class="itemLeft-time">
                <span>{{ item.date }}</span> <span>{{ item.year }}</span>
              </div>
              <div class="itemLeft-jump">
                <div class="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="contentList-right">
            <div
              class="itemRight"
              v-for="(item, index) in indexItemRightdata"
              :key="index"
              @click="handleClickNew(index,indexItemRightdata)"
            >
              <div class="itemRight-tiem">
                <span>{{ item.date }}</span>
                <span>{{ item.year }}</span>
              </div>
              <div class="itemRight-text" v-html="item.text"></div>
              <div class="itemRight-jump"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recentActivities" @click="handleDescribe('https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485273&idx=1&sn=404c91d634e887833855a82ae4a9caa1&chksm=ce757600f902ff16a824ebb6234d45684e205b26f8884b356b35355d3e8489e2cd4ed2c22018&token=789452446&lang=zh_CN#rd')">
      <div class="recentActivities-title">近期活动</div>
      <div class="recentActivities-img">
        <img
          src="@/assets/home/dynamic/indexNews.jpg"
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div class="describe-box">
      <div class="recentActivities-time">
        <span>03</span>
        <span>/</span>
        <span>05</span>
      </div>
      <div class="describe"><span>国际创新赋能 | G-iGREEN碳足迹联合实验室签约</span>
      <span class="describe-text"></span></div>
    </div>
    </div>
  </div>
  <div class="button-more" v-if="show" @click="handleClickMore">
    <button class="check" >查看更多</button>
  </div>
  <div class="dynamic-more" v-else>
    <div class="dynamic-container">
    <div class="industryTrends">
      <div class="industryTrends-title"></div>
      <div class="industryTrends-contentBoxList">
        <div class="itemBox">
          <div class="contentList-left">
            <div
              class="itemLeft"
              v-for="(item, index) in newItemLeftdata"
              :key="index"
              @click="handleJump(index,newItemLeftdata)"
            >
              <div class="itemLeft-title">{{ item.title }}</div>
              <div class="itemLeft-text">{{ item.text }}</div>
              <div class="itemLeft-time">
                <span>{{ item.date }}</span> <span>{{ item.year }}</span>
              </div>
              <div class="itemLeft-jump">
                <div class="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="contentList-right">
            <div
              class="itemRight"
              v-for="(item, index) in newItemRightdata"
              :key="index"
              @click="handleClick(index,newItemRightdata)"
            >
              <div class="itemRight-tiem">
                <span>{{ item.date }}</span>
                <span>{{ item.year }}</span>
              </div>
              <div class="itemRight-text" v-html="item.text"></div>
              <!-- <div class="itemRight-text">{{ item.text }}</div> -->
              <div class="itemRight-jump"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recentActivities" @click="handleDescribe('https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484683&idx=1&sn=d24d4cdd50e47e2b1abced4285b6b57f&chksm=ce757452f902fd4485b83a57dde89253d92356881b88410796f3d73e5574d281cf5f4b32fda9&token=1482181671&lang=zh_CN#rd')">
      <div class="recentActivities-title"></div>
      <div class="recentActivities-img">
        <img
         src="@/assets/home/dynamic/newdynamic.png"
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div class="describe-box">
        <div class="recentActivities-time">
        <span>11</span>
        <span>/</span>
        <span>08</span>
      </div>
      <div class="describe">
        <span>第五届中国国际进口博览会</span>
        <span class="describe-text">西图盟数字科技创始人丁华受邀发表讲话。</span>
      </div>
      </div>

    </div>
  </div>
  <div class="dynamic-container">
    <div class="industryTrends">
      <div class="industryTrends-title"></div>
      <div class="industryTrends-contentBoxList">
        <div class="itemBox">
          <div class="contentList-left">
            <div
              class="itemLeft"
              v-for="(item, index) in itemLeftdata"
              :key="index"
              @click="handleJump(index,itemLeftdata)"
            >
              <div class="itemLeft-title">{{ item.title }}</div>
              <div class="itemLeft-text">{{ item.text }}</div>
              <div class="itemLeft-time">
                <span>{{ item.date }}</span> <span>{{ item.year }}</span>
              </div>
              <div class="itemLeft-jump">
                <div class="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="contentList-right">
            <div
              class="itemRight"
              v-for="(item, index) in itemRightdata"
              :key="index"
              @click="handleClick(index,itemRightdata)"
            >
              <div class="itemRight-tiem">
                <span>{{ item.date }}</span>
                <span>{{ item.year }}</span>
              </div>
              <div class="itemRight-text" v-html="item.text"></div>
              <!-- <div class="itemRight-text">{{ item.text }}</div> -->
              <div class="itemRight-jump"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recentActivities" @click="handleDescribe('https://mp.weixin.qq.com/s/9DD7Q8t4pJfGWEztW7FhCw')">
      <div class="recentActivities-title"></div>
      <div class="recentActivities-img">
        <img
          src="@/assets/home/dynamic/dynamic.png"
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div class="describe-box">
        <div class="recentActivities-time">
        <span>10</span>
        <span>/</span>
        <span>15</span>
      </div>
      <div class="describe"><span>西图盟数字科技召开清华校友研讨会</span></div>
      </div>

    </div>
  </div>
  <div class="button-more">
    <button class="check" @click="handleClickStop">收起信息</button>
  </div>
  </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      indexItemLeftdata: [
        {
          title:
            '第11名！中国创新创业大赛全国赛',
          text: '西图盟数字科技在第十三届中国创新创业大赛新一代信息技术全国赛上位列第11。10月31日至11月2日，从全国37个赛区1',
          date: '2',
          year: '2024.11',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485388&idx=1&sn=26a7bc58148dc124c091171fdab5bd84&chksm=ce757695f902ff83258b618f4b3c7252d583744368a83b4af50c54049404c84f458182575f25&token=789452446&lang=zh_CN#rd',
        },
        {
          title:
            '【培训】企业数字化转型实施路径与管理提升高级研修班顺利举办',
          text: '为深入学习贯彻全国新型工业化推进大会精神，加快数字产业化和制造业数字化转型，以智能制造为主攻方向推动技术变革',
          date: '21',
          year: '2024.10',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485361&idx=1&sn=81f1c909cc189b7720258f829e5917ef&chksm=ce7576e8f902fffe6251b77f8fca9c677d45207d7b07e5187cadb71a7f4876bb8287efcb7cf8&token=789452446&lang=zh_CN#rd',
        },
      ],
      indexItemRightdata: [
        {
          date: '27',
          year: '2024.9',
          text: '西图盟受邀参加科创中国&研究院开放日 “AI+智能制造”研讨会 <br/>  9月24日，科创中国&研究院开放日第十四期“AI+智能制造”研讨会——“AI驱动，传统制造业的智能蝶变”在浙江大学计算机创新技术研究院顺利举办。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485344&idx=1&sn=994ff5ab06daceac98e39eb0309adb0b&chksm=ce7576f9f902ffef57357bc944e8e3603fb7657ce4bdd71b62b55e984e23f4965b68180ac7a4&token=789452446&lang=zh_CN#rd',
        },
        {
          date: '6',
          year: '2024.9',
          text: '《经贸实践》驾驶汽车“智”造新引擎 <br /> “制造强国”任重道远，如何进行产业转型升级？关键在于牢牢把握“创新”。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485337&idx=1&sn=4de9b6b24db079280ee57d8356118f89&chksm=ce7576c0f902ffd6d3589e3ab0bb7ac24dc16fea5e2ab3514b5a61c3b08b268c84b25e55dbc8&token=789452446&lang=zh_CN#rd',
        },
        {
          date: '6',
          year: '2024.9',
          text: '《经贸实践》打造工业元宇宙服务型制造平台 推进数字经济发展 <br/> 浙江省和长三角地区的科技浪潮奔流涌动，造就了中国制造业最具活力和创新力的经济区域之一。然而，随着全球经济形势的变化和科技发展的迅猛，长三角地区的产业结构和发展模式也面临着新的挑战和机遇。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485336&idx=1&sn=fe628dc4b55357f62993d3306c7affc7&chksm=ce7576c1f902ffd79cc06c6970d6293df88c9110a77d0d2c9798c93c1012bedb8a0f8407c55a&token=789452446&lang=zh_CN#rd',
        },
      ],
      newItemLeftdata: [
        {
          title:
            '标杆项目 | 西图盟首个柔性产线技术落地项目获“现场管理成熟度五星级”荣誉',
          text: '西图盟联合实验基地 — 吉利汽车集团中央研究院试制中心总装车间，获中国质量协会的最高级别现场管理评价。',
          date: '27',
          year: '2022.12',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484748&idx=1&sn=5c8bbbcfec8fa3a18c5370deedf61cfd&chksm=ce757415f902fd03f0daf0edee9ce0ae864839c6a85bc2dbbd977675842ee7c8a4d8e122c5b1&token=1482181671&lang=zh_CN#rdg',
        },
        {
          title:
            '战略合作 | 西图盟数字科技与滨江区政府签订战略合作协议',
          text: '为中国参与全球数字贸易规则制定提供“浙江方案”助推构建数字贸易时代新秩序，增创国际合作竞争新优势。',
          date: '12',
          year: '2022.12',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484722&idx=1&sn=ba8f45315189fcd9323eb06422084d7d&chksm=ce75746bf902fd7d56b3c20ac0941abe7b746c4daff102fa9665d1c899240b3242c764f34a62&token=1482181671&lang=zh_CN#rds',
        },
      ],
      newItemRightdata: [
        {
          date: '12',
          year: '2023.01',
          text: '证书荣誉 | 西图盟数字科技被认定为浙江省科技型中小企业',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484775&idx=1&sn=5fdbfa13eb302a8b519e28114c5741bf&chksm=ce75743ef902fd281a822557f10f3720fac3df7ca6b345a1f7aceaf8f407ad5b074a595729e6&token=1482181671&lang=zh_CN#rd',
        },
        {
          date: '27',
          year: '2022.10',
          text: '智造干货 | 超柔性如何止住制造业的“疼”？ <br/> 企业正逐渐走向以消费者为导向的创新型道路，柔性、快速反应正在成为竞争力之一。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484640&idx=1&sn=53a0be9519f4575b034085d92bf727a4&chksm=ce7575b9f902fcafe47a54b6144df8aa552e9d0d6a4e635394929ae805a9ac2ead1b62107fcb&token=1482181671&lang=zh_CN#rd',
        },
        {
          date: '03',
          year: '2022 .11',
          text: '乔迁之喜 | 乔迁典礼上为浙江省数字化设计与制造创新中心揭牌。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484670&idx=1&sn=0e765d5405b23b583cc130e43ad44386&chksm=ce7575a7f902fcb181f99178d6fa1f4759f6a02fdc2bc795c59d7d23800cddc7fd784c95ed89&token=1482181671&lang=zh_CN#rd',
        },
      ],
      itemLeftdata: [
        {
          title:
            '解决方案 | 2022网易数字+大会主论坛分享《数字时代的超柔性产能》',
          text: '我们把以资源为中心的ERP + MES + WMS，进化为以需求为中心的APM + JIS + RAL。',
          date: '27',
          year: '2022.09',
          url: 'https://mp.weixin.qq.com/s/QFNIsFqTUX-RnhErSKq1xw',
        },
        {
          title:
            '项目启动 | 西图盟技术专家组为三锋实业“未来工厂”项目保驾护航！',
          text: '浙江三锋实业股份有限公司创建省级“未来工厂”项目启动会在永康市圆满举行。',
          date: '11',
          year: '2022.08',
          url: 'https://mp.weixin.qq.com/s/toP6X_MGD4Mm3nO5BZF8ug',
        },
      ],
      itemRightdata: [
        {
          date: '26',
          year: '2020.09',
          text: '入会入盟 | 西图盟数字科技获审核通过正式加入浙江省人工智能产业技术联盟',
          url: 'https://mp.weixin.qq.com/s/0G9aEsY33e9PZzkBUVhTgw',
        },
        {
          date: '22',
          year: '2020.08',
          text: '证书荣誉 | 西图盟数字科技通过了软件能力成熟度集成模型V2.13级国际评估认证并正式获得资质证书',
          url: 'https://mp.weixin.qq.com/s/u36t-vavkZolrs1P_qXCwA',
        },
        {
          date: '22',
          year: '2020.07',
          text: '人物专访 | 西图盟孵化业务部王铭洋：用瓷器的骄傲承载烂瓦的野性',
          url: 'https://mp.weixin.qq.com/s/Gg81YkfRZNPw2Ii8BwxHLw',
        },
      ],
      show: true,
    }
  },
  methods: {
    handleJump (index, list) {
      window.open(list[index].url)
    },
    handleClick (index, list) {
      window.open(list[index].url)
    },
    handleJumpNew (index, list) {
      window.open(list[index].url)
    },
    handleClickNew (index, list) {
      window.open(list[index].url)
    },
    handleDescribe (url) {
      window.open(url)
    },
    handleDescribeNew () {
      window.open('https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247485273&idx=1&sn=404c91d634e887833855a82ae4a9caa1&chksm=ce757600f902ff16a824ebb6234d45684e205b26f8884b356b35355d3e8489e2cd4ed2c22018&token=789452446&lang=zh_CN#rd')
    },
    handleClickMore () {
      this.show = false
    },
    handleClickStop () {
      this.show = true
    },
  },
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
