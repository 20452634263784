<template>
  <div class="value-container">
    <!-- <div class="videoContainer"> -->
      <ViewWrapper :size="size"><div class="container-text" >

      <img :src="innerLogo" alt="西图盟">
      <div>造您所想 成您所愿，用数字技术改变生活</div>

      </div></ViewWrapper>
      <div class="container-video">
        <video class="fullscreen-video" id="bgVid" poster="@/assets/home/value/indexImg.png" playsinline="" autoplay="" muted="" loop="" @canplay="loadedVideo">
        <source src="@/assets/home/value/banner.mp4" type="video/mp4">
        </video>
        <!-- <div v-if="showPoster" class="video-poster" :style="{
          'background-image': `url(${require('@/assets/home/value/indexImg.png')})`
        }"></div> -->
      </div>
  </div>
</template>

<script>
import ViewWrapper from '@/components/ViewWrapper'

export default {
  components: { ViewWrapper },
  data () {
    return {
      text: '用数字制造<br/>为工业创造“看得见”的价值',
      size: 'is-large',
      showPoster: true,
      innerLogo: require('@/assets/ec2m.svg'),
    }
  },
  methods: {
    loadedVideo () {
      this.showPoster = false
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
