<template >
  <div class="solution-container"  :style="{backgroundImage:` url(${backgroundImg[backgroundIndex]})`}" >
    <div class="solution-title">节能高效 安全稳定的高性价比系统解决方案</div>
    <div class="solution-content">
      <div class="navigation" >
        <div class="navigationTitle" v-for="(navigation,index) in navigationData" :key="index" @click="handleClick(index)"  :class="{ 'is-active': activedIndex === index }"><span>{{navigation}}</span></div>
      </div>
      <div class="information" v-for="(information,idx) in informationData" :key="idx" >
        <div  v-if="activedIndex === idx">
          <div class="informationTitle">{{information.informationTitle}}</div>
        <div class="programmeBox"  >
          <div class="programme">应用方案</div>
        <div class="informationProgramme">{{information.informationProgramme}}</div>
        </div>
        <div class="gainsBox">
          <div class="gains">价值收益</div>
        <div class="informationGains" v-for="(gains,indexs) in information.informationGains" :key="indexs"><span>0{{indexs+1}} {{gains}}</span></div>
        </div>
        <div class="core">
          <div class="coreTile">核心优势：</div>
          <div class="coreContent" v-for="(core,idex) in information.core" :key="idex">
            <span>{{core}}</span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navigationData: ['G-iVisual元工厂', '数字孪生-虚拟工厂', '多式全链-智慧物流', '生产设备管理资产', '精益管理智能工厂', '工业信用自证区块链'],
      informationData: [{
        informationTitle: 'G-iVisual元工厂',
        informationProgramme: '在虚拟工厂基础上，进一步实现时空同步，打造毫米级空间，其精度丝毫不亚于实体工厂，可实现工厂透明、双向、实时、沉浸式的管理，在虚拟空间驾驭实体工厂，能进行产前预测、生产验证、模拟调试、立体管控、远程作业，漫游工厂，增强企业对众多资源要素的管控，降本增效，突破发展。',
        informationGains: ['成本节约', '体验提升'],
        core: ['时空同步', '透明实时', '降本增效'],
      },
      {
        informationTitle: '数字孪生-虚拟工厂',
        informationProgramme: '虚拟工厂解决方案－提供包含数字化仿真、机器人离线、虚拟调试、虚拟生产验证的全流程零风险解决方案。不管是新工厂的搭建还是工艺的变更，应用本解决方案，可实现无现场调试人员参与情况下的无风险测试，大幅降低设备集成时的调试风险。',
        informationGains: ['成本节约', '体验提升'],
        core: ['虚拟调试', '生产验证', '降本风险'],
      },
      {
        informationTitle: '多式全链-智慧物流',
        informationProgramme: 'G-iILS智慧物流致力于物流全链路多渠道的数智化，为客户提供覆盖OMS（订单管理）—TMS（运输管理）—WMS（仓储管理）—BMS（结算管理）全链条管理方案，通过搭建智能配载与调度G-iRO路径优化系统及多AGV调度、智能分拣、机器人控制等系统方案，可实现物流全流程透明可控，快速响应需求变化，保证用户最佳体验。',
        informationGains: ['成本节约', '体验提升'],
        core: ['全链方案', '透明可控', '快速响应'],
      },
      {
        informationTitle: '生产设备管理资产',
        informationProgramme: '基于IOT和AI技术，实现从设备互联、数据采集存储、数据监控到AI分析的一站式设备管理，让每台设备从被动维修、周期检测转变为智能预测维护，为企业提供设备预测性维护、能耗管理、远程运维等定制化服务，实现企业的节能降本增效。',
        informationGains: ['成本节约', '体验提升'],
        core: ['一站管理', '智能预测', '降本增效'],
      },
      {
        informationTitle: '精益管理智能工厂',
        informationProgramme: '为离散制造企业提供一站式及时响应的生产运营服务。通过对人、机、料、法、环、测的实时监控与分析，根据企业现状提供MES、LES、QMS等信息系统及工程增值服务，实现对生产全过程全要素的透明化管理和精益改善。',
        informationGains: ['成本节约', '体验提升'],
        core: ['实时监控', '及时响应', '精益改善'],
      },
      {
        informationTitle: '工业信用自证区块链',
        informationProgramme: '区块链的分布式存储技术可以将文件存储在不同的空间上，同时生成文件存储确权证明，在可靠性、边缘存储成本、数据隐私保护方面比传统云存储具有一定优势。以区块链技术解决信任问题，以算法程序表达规则，使工业产品在自证唯一标识、产业链协同、供应链金融具有新的高效率、低成本的普惠方案。',
        informationGains: ['成本节约', '体验提升'],
        core: ['隐私保护', '普惠方案', '数据可靠 '],
      },
      ],
      backgroundIndex: 0,
      backgroundImg: [require('@/assets/home/solution/pg-yuanfactory.jpg'), require('@/assets/home/solution/pg-virtualfactory.jpg'), require('@/assets/home/solution/pg-boring.jpg'), require('@/assets/home/solution/pg-management.jpg'), require('@/assets/home/solution/pg- intellect.jpg'), require('@/assets/home/solution/pg-blockchain.jpg')],
      activedIndex: 0,
    }
  },
  methods: {
    handleClick (index) {
      this.backgroundIndex = index
      this.activedIndex = index
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/font/font.less';
@import './index.less';
</style>
