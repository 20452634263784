<template>
    <div class="footer">
      <ViewWrapper>
    <div class="content">
      <div class="content_left ">
      <div class="icon"><img src="@/assets/QRcode.png" alt=""></div>
      <div class="content_left_text">
        <span>联系我们</span>
        <span>邮箱：god.service@c2m-mom.com </span>
        <span>时间：9:00-18:00</span>
        <a href="mailto:god.service@c2m-mom.com"><button class="button">联系我们</button></a>
      </div>
    </div>
    <div class="content_right ">
      <div class="content_right_text">
        <router-link
          v-for="btn in btns"
          :key="btn.text"
          :to="{ path: btn.path }"
          class="btn"
        ><span>{{btn.text}}</span></router-link>
      </div>
    </div>
    </div>
      </ViewWrapper>
    <div class="end">
      <div class="copyright">
        <span>Copyright©{{nowYear}} 浙江西图盟数字科技有限公司版权所有</span>
        <div style="display: inline-block; margin-left: 30px;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012056"><img src="~@/assets/gongan.png" />浙公网安备 33010602012056号</a>
          <a class="beian-target" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021033589号-1</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ViewWrapper from '@/components/ViewWrapper'
export default {
  components: {
    ViewWrapper,
  },
  data () {
    return {
      nowYear: new Date().getFullYear(),
      btns: [
        { text: '解决方案', path: '/carIndustry' },
        { text: '产品与服务', path: '/product/getaverse' },
        { text: '关于我们', path: '/about' },
      ],
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
  .footer{
    position: relative;
    margin: 0 auto;
    min-width: 1080px;
    background: #fff;
    margin-top: -30px;
    .content{
    width: 1400px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-top: 71px;
      .content_left{
        float: left;
    //   flex: 1;
    //  text-align: center;
    //  margin-top: -100px;
      .icon{
        display: inline-block;
        vertical-align: top;
      }
      .content_left_text{
        display: inline-block;
        font-size: 16px;
        margin-top: 27px;
        margin-left: 45px;
        // position: relative;
        // text-align: left;
        // top: 50%;
        // left: 45px;
        span {
          display: block;
          text-align: left;
          margin-bottom: 5px;
          &:first-child {
            margin-bottom: 15px;
          }
        }
        .button {
          margin-top: 10px;
          height: 52px;
          width: 185px;
          background: #464A58;
          border: 0;
          color: #fff;
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            transition: 0.2s;
            background: #0057FF;
          }
        }
      }
    }
    .content_right{
      float: right;
      // flex: 1;
      .content_right_text{
        text-align: center;
        a{
          text-decoration: none;
          :link{
            color: #000000;
          }
          :visited{
            color: #000000;
          }
          :hover {
          color: #0057FF;
        }
        &:not(:last-child){
          margin-right: 48px;
        }
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #000000;
        }
      }

    }

    }
    .end{
      position: relative;
      margin-top: 78px;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        top: -28px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #EDEFF4 ;
      }
    }
  }

.copyright {
  &, a {
    color: #666666;
  }
  .beian-target {
    font-size: 12px;
    margin-left: 25px;
  }
}
</style>
