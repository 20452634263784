<template>
  <div class="transformation-container">
    <div class="transformation-title">领先的产品和服务 助力企业数字化转型</div>
    <div class="iconTileBox">
      <div class="iconTile" v-for="(item,index) in iconTile" :key="index">
      <div class="icon"><img :src="item.icon" alt="" ></div>
      <div class="title">{{item.title}}</div>
    </div>
    </div>
    <div class="navigation" ><Navigation @changeText ="updateIndex"/></div>
    <div class="informationBox">
      <div class="information" v-for="(items,index) in informationData" :key="index" >
      <div class="icon" v-if="informationShow === index"><img :src="items.icon" alt="" height="100%" width="100%" class="img"></div>
      <div class="text" v-if="informationShow === index">
        <div class="text-title">{{items.title}}</div>
        <div class="subtitle">{{items.subtitle}}</div>
        <div class="content">{{items.content}}</div>
        <div class="statistic" v-for="(statistic,idex) in items.statistic" :key="idex">
          <div class="number">{{statistic.number}}</div>
          <div class="statistic-title">{{statistic.text}}</div>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>
<script>
import Navigation from './navigation/index.vue'
export default {
  components: {
    Navigation,
  },
  data () {
    return {
      iconTile: [{
        icon: require('@/assets/home/transformation/icIntelligence.svg'),
        title: '智能',
      },
      {
        icon: require('@/assets/home/transformation/icEfficient.svg'),
        title: '高效',
      },
      {
        icon: require('@/assets/home/transformation/icSecurity.svg'),
        title: '安全',
      },
      {
        icon: require('@/assets/home/transformation/icStable.svg'),
        title: '稳定',
      }],
      informationData: [{
        icon: require('@/assets/home/transformation/twin.png'),
        title: '数字孪生',
        subtitle: '构建HCPS、物理与数字空间双向驱动的新制造体系',
        content: '工业制造的转型升级离不开数字孪生技术，通过物理车间与虚拟车间的双向真实映射与实时交互，实现物理车间、虚拟车间、车间服务系统的全要素、全流程、全业务数据的集成和融合。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],

      },
      {
        icon: require('@/assets/home/transformation/logistics.png'),
        title: '智慧物流',
        subtitle: '订单驱动物流自适应调整',
        content: '通过个性化定制物流解决方案，实现客户需求预测精准化、生产制造柔性化、过程可视化、库存管理透明化、物流配送无人化。有效提升物流及供应链协同效率、降低库存和交付风险、增强用户体验感。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],
      },
      {
        icon: require('@/assets/home/transformation/IndustrialData.png'),
        title: '工业AI & 大数据',
        subtitle: 'AI加持，提升工业数据价值',
        content: '通过大数据和AI对工业数据进行分析建模，沉淀工业知识和经验，在原有业务逻辑基础上形成AI应用，如节能降耗、设备预测性维护、工艺优化、AI质检等，以此实现企业业务优化和创新。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],
      },
      {
        icon: require('@/assets/home/transformation/IndustrialThings.png'),
        title: '工业物联',
        subtitle: '智能制造数字化转型的底座',
        content: '广泛连接设备，通过智能设备终端采集数据，通过物联平台实现各类数据的在线连接、管理、分析、应用，全面助力物联网时代企业数字化、智能化创新。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],
      },
      {
        icon: require('@/assets/home/transformation/IndustrialBlockchain.png'),
        title: '工业区块链',
        subtitle: '为产业数字化变革赋能',
        content: '工业区块链在产业链协同、供应链金融、产品全生命周期管理和溯源、共享制造等工业互联网相关领域的作用是显性的，尤其是非常适合高附加值的产品。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],
      },
      {
        icon: require('@/assets/home/transformation/IntelligentManufacturing.png'),
        title: '智能制造',
        subtitle: '触手可及的智能工厂',
        content: '源自工厂一线，回归制造本质，打开工厂黑箱，融合OT运营、IT数字化、AT自动化等技术，对制造过程科学把控，让生产制造的过程变得透明。',
        statistic: [{
          number: '78',
          text: '技术专利',
        },
        {
          number: '325',
          text: '使用客户',
        },
        {
          number: '526',
          text: '覆盖场景',
        }],
      },
      ],
      informationShow: 0,
    }
  },
  methods: {
    updateIndex (index) {
      this.informationShow = index
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/font/font.less';
@import './index.less';
</style>
