<template>
  <div class="programme-container">
    <div class="programme-title">服务多职能 提质增效新方案</div>
    <div class="programme-contentList">
      <div class="contentItem" v-for="(item,index) in programmeData" :key="index">
        <div class="programme-img"><img :src="item.img" alt="" height="100%" width="100%"></div>
        <div class="item-substance">
          <div class="programme-icon"><img :src="item.icon" alt=""></div>
        <div class="contentTitle"><span>{{item.contentTitle}}</span></div>
        <div class="programme-text">{{item.text}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      programmeData: [{
        img: require('@/assets/home/programme/production.png'),
        icon: require('@/assets/home/programme/production.svg'),
        contentTitle: '生产人员 ',
        text: '用信息化手段赋能工人工作，给一线员工安全舒适、便捷的生产环境，同时帮助工人提高效率。',
      },
      {
        img: require('@/assets/home/programme/inspect.png'),
        icon: require('@/assets/home/programme/inspect.svg'),
        contentTitle: '检测人员 ',
        text: '质量检验环节数字化升级，既能确保质量检验计划和控制计划能得到切实执行，又能提高质量检验和质量分析效率。',
      },
      {
        img: require('@/assets/home/programme/operate.png'),
        icon: require('@/assets/home/programme/operate.svg'),
        contentTitle: '运营人员 ',
        text: '强大的溯源系统，能从源头确保质量数据的可靠性、完整性和及时性，还可为实时在线生产过程控制提供数据源。',
      },
      {
        img: require('@/assets/home/programme/administration.png'),
        icon: require('@/assets/home/programme/administration.svg'),
        contentTitle: '管理人员 ',
        text: '数字化改造让企业大大降低企业的人力、时间和管理成本，让、管理者有更多的精力放在企业经营管理本身，和市场和客户身上。',
      }],
    }
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
