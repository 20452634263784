<template>
  <div class="view-wrapper" :class="size">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
