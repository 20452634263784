<template>
  <div class="home">
    <Value/>
      <ViewWrapper :size="size">  <Design/></ViewWrapper>
    <ViewWrapper :size="size">  <Transformation/></ViewWrapper>
      <Solution/>
      <div style="background: #F3F6FA;">
    <!-- <ViewWrapper :size="size" >  <Service/></ViewWrapper> -->
  </div>
    <ViewWrapper :size="size">  <Programme/></ViewWrapper>
    <Framework/>
    <div style="background: #F3F6FA;">
    <ViewWrapper :size="size"> <Dynamic/></ViewWrapper>
  </div>
    <Join/>
    <ViewFooter/>
  </div>
</template>
<script>
import ViewWrapper from '@/components/ViewWrapper'
import Value from './value'
import Transformation from './transformation'
import Design from './design'
import Solution from './solution'
// import Service from './service'
import Programme from './programme'
import Framework from './framework'
import Dynamic from './dynamic'
import Join from './join'
import ViewFooter from '@/components/ViewFooter'

export default {
  name: 'home',
  components: {
    ViewWrapper,
    Value,
    Transformation,
    Solution,
    // Service,
    Programme,
    Framework,
    Dynamic,
    Join,
    ViewFooter,
    Design,
  },
  data () {
    return {
      size: 'is-large',
    }
  },
}
</script>

<style lang="less" scoped>
.home {
  margin-top: -120px;
  margin-bottom: 78px;
  min-width: 1080px;
}
</style>
