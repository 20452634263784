<template>
  <div class="navigation">
    <div class="navigationContent">
      <div class="titleList" v-for="(item,index) in navigationData" :key="index" @click="handleClick(index)">
        <div class="title">{{item.title}}</div>
        <div class="line" v-if="type === index"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
      navigationData: [{
        title: '数字孪生',
      },
      {
        title: '智慧物流',
      },
      {
        title: '工业AI & 大数据',
      },
      {
        title: '工业物联',
      },
      {
        title: '工业区块链',
      },
      {
        title: '智能制造',
      }],
      type: 0,
    }
  },
  methods: {
    handleClick (index) {
      this.type = index
      this.$emit('changeText', index)
    },
  },
}
</script>

<style lang="less" scoped>
  .navigation{
    min-width: 1080px;
    height: 76px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    .navigationContent{
      padding: 24px 0px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      .titleList{
        display:inline-block;
        position: relative;
        &:not(:last-child){
          margin-right: 136px;
        }
        .title{
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color:#1D2129;
        }
        .line{
          position: absolute;
          bottom: -50%;
          left: 50%;
          transform: translateX(-50%) translateY(10px);
          width: 75px;
          height: 4px;
          background: #0057FF;
        }
      }
    }
  }
</style>
